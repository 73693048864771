import { useEffect } from 'react';

import { useRouter } from 'next/router';

export default function() {
  const { push } = useRouter();

  useEffect(() => {
    push('https://www.solonadvisory.com');
  });
  return <></>;
}
